import { graphql, PageProps } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = PageProps<GatsbyTypes.NotFoundPageQuery>;

const NotFoundPage: React.FC<Props> = ({ data }) => {
  const siteMetadata = data.site?.siteMetadata;
  const siteTitle = siteMetadata?.title || '';
  const siteDescription = siteMetadata?.description || '';

  return (
    <Layout title={siteTitle} description={siteDescription}>
      <SEO title='404: Not Found' />
      <h1>お探しのページが見つかりませんでした。</h1>
      <p>申し訳ありません。指定されたページが見つかりませんでした。</p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
